exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-logomap-js": () => import("./../../../src/pages/logomap.js" /* webpackChunkName: "component---src-pages-logomap-js" */),
  "component---src-pages-logos-js": () => import("./../../../src/pages/logos.js" /* webpackChunkName: "component---src-pages-logos-js" */),
  "component---src-pages-packs-js": () => import("./../../../src/pages/packs.js" /* webpackChunkName: "component---src-pages-packs-js" */),
  "component---src-pages-sources-js": () => import("./../../../src/pages/sources.js" /* webpackChunkName: "component---src-pages-sources-js" */),
  "component---src-templates-logo-detail-js": () => import("./../../../src/templates/logo-detail.js" /* webpackChunkName: "component---src-templates-logo-detail-js" */),
  "component---src-templates-pack-detail-js": () => import("./../../../src/templates/pack-detail.js" /* webpackChunkName: "component---src-templates-pack-detail-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/support.js" /* webpackChunkName: "component---src-templates-support-js" */)
}

